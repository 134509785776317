.wrapperModal {
  // background: url('../images/right_preview.jpg')  rgba(0, 0, 0, 0.4);
  background-size: cover;
  background-blend-mode: darken;
  // height: 100vh;
  display: flex;
  align-items: center;

  .modalContainerRef {
    width: 540px;
    margin: auto;
    padding: 10px 24px 50px 30px;
    background-color: white;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding-top: 40px;
    // padding-bottom: 80px;


    .titleCont{

      h2{
          font-size: 3em,
      }
  
      span {
          font-size: 1.0em,
      }
    }

    #contact-form input{
      width: 446px;
    }

    #contact-form .selectButt{
      width: 485px;
    }
  

    @media screen and (max-width: 500px) {
      width: 330px;

      #contact-form input{
        width: 250px;
      }

      #contact-form .selectButt{
        width: 288px;
      }
      .titleCont{
        h2{
            font-size: 2.3em,
          
        }
    
        span {
            font-size: 0.7em,
        }
    }


    }
    @media screen and (max-width: 300px) {
      width: 280px;
      padding: 30px 0;
    }
    .modal_container {
      text-align: center;
      .title_success {
        text-align: center;
        font-size: 35px;
        font-weight: 500;
        margin-bottom: 0;
        color: #19569c;
      }
    }
    .thanksText {
      width: 100%;
      margin: 200px auto;
      text-align: center;
      .title_success {
        text-align: center;
        font-size: 35px;
        font-weight: 500;
        margin-bottom: 0;
        color: #19569c;
      }
    }

    .form {
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      @media screen and (max-width: 500px) {
        margin-top: 10px;
      }
    }

    .inputs_col {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 20px;
      margin-bottom: 40px;

      & > * {
        flex: 0 0 calc(50% - 20px);
        max-width: calc(50% - 20px);
      }

      @media screen and (max-width: 650px) {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      @media screen and (max-width: 500px) {
        & > * {
          flex: 0 0 100%;
          max-width: 100%;
        }

        gap: 10px;
      }
    }

    .submit {
      font-family: inherit !important;
      color: white !important;
      width: calc(50% - 20px);
      height: 50px;
      font-size: 16px !important;
      font-weight: 600 !important;
      background: linear-gradient(90deg, #1ddee1, #43beef);

      &:hover {
        background: linear-gradient(45deg, #1d3c5a, #1d3c5a);
      }

      &.process{
        background: linear-gradient(90deg, #ccc, #bbb);
      }
      // background: linear-gradient(45deg, #06aede, #06aede);
      // border-radius: 1px !important;

      @media screen and (max-width: 500px) {
        width: 100%;
      }
    }

    .numberInput {
      -webkit-appearance: none;
      margin: 0;
      -moz-appearance: textfield;
    }
  }
}
