
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.main {

  position: relative;
  min-height: 100vh;
  padding: 40px 50px 100px;
  color: #19569c;

  header{
      
    .mobileLogo{
      display: none;
    }

    .promotinal {
      position: absolute;
      top: 0;
      margin-right: 55px;
      right: 0%;
      opacity: 0.9;
      cursor: pointer;

      img {
          width: 100%;
          max-width: 175px;
          filter: drop-shadow(0px 2px 4px rgba(0,0,0,.3));
      }

    }

  }

  .modal-header {
    height: 40px;
    margin-bottom: 10px;
    text-align: left;
  }

  .content_title {

    &.incontent{
      display: block;
    }
    &.inheader{
      display: none;
    }
  }

  .d-flex.postal_input {
    display: flex !important;

    .postal_input {
      padding: 10px;
      color: #333;
      font-family: Roboto;
      width: 100%;
      border: 1px solid #000;
      -webkit-flex: 1 1;
      flex: 1 1;
    }

    .blue-btn {
      background: rgb(238, 238, 238);
      border-radius: 4px;
      border: 1px solid rgb(187, 187, 187);
      margin-top: 4px;
      cursor: pointer;
      padding: 10px;
      margin-left: 10px;
      color: black;
      background: #eee;
      border-radius: 4px;
      border: 1px solid #bbb;
      margin-top: 4px;
    }
  }

  .backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    padding: 50px;
    z-index: 10000;

    @media screen and (max-width: 420px) {
      padding: 20px;
      .modal {
        padding: 20px;
      }
      h2 {
        font-size: 21px;
      }
    }

    @media screen and (max-width: 320px) {
      h2 {
        font-size: 17px;
      }
    }

  }

  .geolocation-tab {
    position: fixed;
    bottom: 0px;
    padding: 12px;
    padding-bottom: 8px;
    left: 40px;
    z-index: 10000;
    background-color: #234c74;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    transition: all 0.25s linear;
    cursor: pointer;

    &:hover {
      background-color: rgb(56, 56, 56);
      //padding: 15px;
      //padding-bottom: 12px;
      transform: scale(1.05)
    }

    span {
      font-size: 16px;
      color: white;
      padding: 5px;
      display: inline-block;
    }

    img {
      width: 24px;
      height: 24px;
      display: inline-block;
      float: left;
      margin-right: 5px;
    }



  }

  .bg_container {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -3;

    .gradient {
      position: absolute;
      top: 0%;
      left: 0;
      right: 0;
      z-index: 0;
      height: 100%;
    }

    .left {
      position: absolute;
      bottom: 0;
      left: 0;
      top: 0;
      width: 25%;
      background: url(./assets/images/Landing/bg_left_sm.png), url(./assets/images/Landing/bg_left_green.png);
      background-size: contain;
      background-position: bottom right;
      background-repeat: no-repeat;
      z-index: -1;
    }

    .right {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 75%;
      background: url(./assets/images/Landing/bg_right_sm.jpg);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: right bottom;
      z-index: -2;
    }
  }

  .phone_icon {
    border: none;
    outline: none;
    display: none;
  }

  @media screen and (max-width: 767px) {
    .geolocation-tab {
      width: 100%;
      left: 0px;
      border-radius: 0px;
    }

    // header{

    //   .promotinal {
    //    margin-right: 250px;
      
    //   }

    // }
  }

  @media screen and (max-width: 660px) {
    // padding: 40px 0;
    padding: 1px 0 40px 0;

    header{
      
      .mobileLogo{
        display: block;
        display: block;
        left: 20px;
        top: 20px;
        position: absolute;
      }
      
      .promotinal {
        width: 200px;
        right: 10px;
        // left: 50%;
        margin-right: -25px;
        left: calc(100vw - 160px);

        img{
          max-width: 140px;
        }

      }
  
    }
    .logo{
      visibility: hidden;
    }

    .phone_icon {
      display: block;
      position: fixed;
      left: 20px;
      bottom: 64px;
      width: 62px;
      height: 62px;
      background: url(./assets/images/Landing/phone.svg);
      animation: bounce 4s ease-in-out infinite 2s;
      border-radius: 50%;
      filter: drop-shadow(2px 4px 3px #0000004f);
    }

    .bg_container {
      height: 100vh;

      .gradient {
        background: linear-gradient(0deg, rgba(255, 255, 255, 0) 32vh, rgba(255, 255, 255, 1) 100vh);
      }

      .left {
        width: 240px;
        background-image: url(./assets/images/Landing/bg_left_sm.png);
        background-position: -30px bottom;
      }

      .right {
        background-position: 76px bottom;
        width: 640px;
      }
    }
    .content {
      margin-top: 152px;
    }
    .content_title {
      margin-bottom: 8px;
      margin-top: -15px;
            
      &.incontent{
        display: none;
      }
      &.inheader{
        display: block;
      }
    }

    header{
      position: fixed;
      background: #fff;
      z-index: 1;
      margin-top: -1px;
      padding-top: 20px;

    }
  }

  @media screen and (max-width: 420px) {
    header{
      .promotinal {
        width: 150px;
        margin-right: 0px;
        
      }

      .mobileLogo{
        width: 140px;
      }
    }
  }

  @media screen and (max-width: 360px) {
    header{
      .promotinal {
        width: 152px;
      }

      .mobileLogo{
        width: 114px;
      }
    }
  }

}

.d_none {
  display: none;
}

.header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 40px;
  margin-bottom: 20px;

  .logo {
    display: flex;

    img {
      filter: drop-shadow(1px 1px 0px #ebebeb);
    }
  }

  @media screen and (min-width: 2000px) {
    margin-bottom: 100px;
    .logo {
      height: 100px;
    }
  }
  @media screen and (max-width: 830px) {
    margin-bottom: 80px;
    .logo {
      height: 55px;
    }
  }

  @media screen and (max-width: 660px) {
    justify-content: center;
    margin-bottom: 25px;
    .phone_wrap {
      display: none;
    }
  }

}

.phone_wrap {
  display: flex;
  align-items: center;
  margin-left: auto;
  text-decoration: none;
  justify-content: center;

  .phone_number {
    font-size: 24px;
    font-weight: 400;
    margin-left: 30px;
  }

  @media screen and (min-width: 1600px) {
    img {
      width: 32px;
      height: 32px;
    }
    // .phone_number {
    //   font-size: 24px;
    // }
  }
  @media screen and (max-width: 830px) {
    img {
      width: 24px;
      height: 24px;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  &_title {
    text-align: center;
    font-size: 55px;
    margin-bottom: 70px;
    font-weight: 500;
  }

  .link_list_wrap {
    display: grid;
    gap: 30px;

    .link_list {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      width: 100%;
      gap: 70px;

      .link_item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 155px;

        &_button {
          border: none;
          outline: none;
          background: transparent;
          padding: 5px;
        }

        .image_wrap {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 120px;
          height: 120px;
          border-radius: 50%;
          box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.06);
          border: 1px solid #f9f6f6;
          margin-bottom: 20px;
          background: white;
          cursor: pointer;

          &:hover {
            background: linear-gradient(45deg, #272d83, #009bc5);
            box-shadow: 0 0 4px 1px rgba(255, 255, 255, 0.1);
            border: 1px solid #009bc5;

            img {
              filter: brightness(0) invert(1);
            }
          }

          &.active {
            background: linear-gradient(45deg, #272d83, #009bc5);
            box-shadow: 0 0 4px 1px rgba(255, 255, 255, 0.1);
            border: 1px solid #009bc5;

            img {
              filter: brightness(0) invert(1);
            }
          }

          img {
            width: 50%;
            object-fit: contain;
          }

        }

        .image_title {
          font-size: 20px;
          font-weight: 600;
          max-width: 100%;
          overflow-wrap: break-word;
          text-align: center;
        }

        .link_item_description {
          display: flex;
          margin-top: 20px;
          background: grey;
          color: white;
          border-radius: 5px;
          padding: 5px 10px;
          text-align: center;
          font-size: 12px;
        }
      }
    }

    .link_list.promot {
      display: flex;
      z-index: 1;
      margin-left: -2px;
      grid-template-columns: none;

        gap: 38px;

      .link_item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 60px;
        flex-wrap: nowrap;

        .image_wrap{
          width: 80px;
          height: 80px;
          margin-bottom: 3px;
        }

        .image_title {
          font-size: 10px;
          display: flex;
          color: #224c74;
        }
      }
    }
  }

  .link_item.mobile {
    display: none !important;
  }

  @media screen and (min-width: 1600px) {
    &_title {
      font-size: 75px;
    }
    .link_list_wrap {
      gap: min(4vw, 4vh);

      .link_list {
        gap: min(4vw, 4vh);

        .link_item {
          width: max(10vw, 10vh);

          .image_wrap {
            width: max(8vw, 8vh);
            height: max(8vw, 8vh);
          }

          .image_title {
            font-size: min(2vw, 2vh);
          }
        }
      }
    }
  }
  @media screen and (max-width: 900px) {
    &_title {
      font-size: 32px;
      font-weight: 600;
      line-height: 40px;
      margin-bottom: 40px;
    }
    .link_list_wrap {
      .link_list {
        grid-template-columns: repeat(2, 1fr);
        gap: 50px;

        .link_item {
          width: 180px;

          .image_title {
            font-weight: 600;
          }

          .image_wrap {
            width: 150px;
            height: 150px;
            margin-bottom: 0;

            img {
              width: 40%;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 660px) {
    margin-bottom: 60px;
    &_title {
      font-size: max(24px, 7.5vw);
      margin-bottom: 40px;
    }
    .link_list_wrap {
      margin-bottom: calc(100vh - 462px);
      gap: 40px;

      .link_list {
        grid-template-columns: repeat(1, 1fr) !important;
        gap: 40px;

        .link_item.desktop {
          display: none !important;
        }
        .link_item.mobile {
          display: flex !important;
        }
      }

      .link_list.promot {
        display: grid;
        z-index: 1;
        margin-left: -20px;
        grid-template-columns: repeat(3, 1fr) !important;
        flex-direction: column;
      }
    }

  }
  @media screen and (max-width: 380px) {
    .link_list_wrap {
      .link_list {
        .link_item {
          transform: scale(1.1);
          margin-bottom: 28px;
        }
      }
    }
  }
}

@keyframes bounce {
  0%, 20%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
}